@import url('https: //fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&amp;display=swap');

.active>a {
  color: #CC0153 !important;
}

li.active {
  color: #CC0153;
}

li.active>a {
  color: #CC0153;
}

.ni-primary {
  color: #CC0153;
}

.image-fade {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}